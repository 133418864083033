import { render } from '@testing-library/react';
import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { motion } from "framer-motion"
const parse = require('html-react-parser');



function ElementTyper(props) {
 const [typing, setTyping] = useState(1);
 if(typing == 1){
  return(
   <TypeAnimation
   sequence={[
     1000, // Waits 1s
     props.element,
     1000, // Waits 1s
     () => {
      setTyping(0); // Place optional callbacks anywhere in the array
     }
   ]}
   wrapper="div"
   speed="80"
   cursor={true}
   repeat={Infinity}
   className={props.className}
 />
  );
 }else{
  return(
   <>
   <motion.motion
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 1 }}
   >
   {parse(props.element)}
   </motion.motion>
   </>
  )
 }
}

export default ElementTyper;